import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { HotkeysService } from '@ngneat/hotkeys';
import { lastValueFrom } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { MenuItem } from 'src/lib';
import { DelegateService } from '../../services/delegate-service.service';
import { GlobalSearchResultUI, GlobalSearchService } from '../../services/global-search.service';
import { SourceEntityType } from 'src/lib/newBackendTypes';
import { PreventableEvent } from '@progress/kendo-angular-common';

@Component({
  selector: 'global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
})
export class GlobalSearchComponent {
  @ViewChild('searchBar', { static: false })
  searchBar: ComboBoxComponent;

  // FormGroup wrapper used to interact with the view
  searchTerm: UntypedFormControl;

  results: GlobalSearchResultUI[] = [];

  searching: boolean;

  prompt: string;

  actualSelection: SearchResult;

  contactEntityType = SourceEntityType.ALF_CODE;
  contractEntityType = SourceEntityType.CONTRACT_KEY;

  shortcuts: string[] = [];
  isFocused: boolean = false;
  searchInputValue: string | null = null;

  constructor(
    private searchService: GlobalSearchService,
    private router: Router,
    private delegate: DelegateService,
    private hotkeys: HotkeysService,
  ) {
    this.searchTerm = new UntypedFormControl();
    this.searching = false;
    this.prompt = 'Please start typing';
  }

  ngOnInit(): void {
    this.addShortcuts();
  }

  ngAfterViewInit(): void {
    if (this.searchBar) {
      this.searchBar.filterChange.pipe(debounceTime(300)).subscribe((filter) => {
        this.handleFilter(filter);
      });
    }
  }

  private addShortcuts() {
    this.hotkeys.addShortcut({ keys: 'shift.meta.f', allowIn: ['INPUT', 'TEXTAREA', 'SELECT'], description: 'Focus global search', group: 'Global Search' }).subscribe(() => {
      if (!this.searchBar?.isFocused) {
        this.searchBar.focus();
        this.isFocused = true;
      }
      return false;
    });

    this.hotkeys.addShortcut({ keys: 'escape', allowIn: ['INPUT', 'TEXTAREA', 'SELECT'], description: 'Unfocus global search', group: 'Global Search' }).subscribe(() => {
      if (this.searchBar?.isFocused) this.deleteSearchValue();
      return false;
    });

    this.shortcuts.push('shift.meta.f', 'escape');
  }

  async handleFilter(value: string) {
    if (value && value.length > 0) {
      if (value !== this.searchInputValue) {
        this.searchInputValue = value;
        this.prompt = 'No results';
        this.searching = true;
        this.results = await lastValueFrom(this.searchService.search(value).pipe(tap((r) => (this.searching = false))));
      }
    } else {
      this.searchInputValue = null;
      this.results = [];
      this.prompt = 'Please start typing';
    }
  }

  setSearchValue(selection: SearchResult, newTab: boolean) {
    const allowSelectResult = newTab || (this.results.length === 1 && !newTab);
    if (!allowSelectResult) return;
    this.actualSelection = selection;
    this.selectResult(this.actualSelection, newTab);
  }

  onKeydown(newTab: boolean) {
    this.setSearchValue(this.searchBar.value, newTab);
    this.searchBar.text = this.searchInputValue;
    this.searchBar.filterChange.emit(this.searchInputValue);
  }

  deleteSearchValue() {
    this.searchTerm.setValue(null);
    this.searchInputValue = null;
    this.isFocused = false;
    this.searchBar.toggle(false);
    this.results = [];
    setTimeout(() => {
      this.searchBar.blur();
    });
  }

  selectResult(selection: SearchResult | null, newTab: boolean) {
    if (selection === undefined || selection === null) return;
    this.hotkeys.removeShortcuts(this.shortcuts);
    if ('effect' in selection) {
      selection.effect(this.delegate).subscribe((res) => {});
    } else if ('entityId' in selection && typeof selection.entityId === 'number') {
      if (!newTab) this.router.navigate([selection.link, selection.entityId]);
      else window.open(`${selection.link}/${selection.entityId}`);
    } else {
      if (!newTab) this.router.navigate([selection.link]);
      else window.open(`${selection.link}`);
    }
    this.addShortcuts();
    this.deleteSearchValue();
  }

  public itemDisabled(itemArgs: { dataItem: GlobalSearchResultUI; index: number }) {
    return !itemArgs.dataItem.link && !itemArgs.dataItem.effect;
  }

  onClose(event: PreventableEvent) {
    event.preventDefault();
  }
}

export type SearchResult = {
  uuid: string;
} & (GlobalSearchResultUI | MenuItem);
